import React, {useState} from "react";
import "./IncreamentDecreament.css"

const IncreamentDecreament = () => {
    const [number, setNumber] = useState(1);
    const handleMinusClick = () => {
        if (number > 1) {
            setNumber(number - 1);
        }
    };

    const handlePlusClick = () => {
        setNumber(number + 1);
    };

    const [showButtons, setShowButtons] = useState(false);
    const [show, setShow] = useState(false);
    return (
        <>
        <section className="IncreamentDecreament">
        <div id="second-div" className="shopping-img-align">
                <div className=" add-cart-box">
                    <button className="minus " onClick={() => handleMinusClick()}>
                        -
                    </button>
                    <div className="quantity">{number}</div>
                    <button className="add " onClick={() => handlePlusClick()}>
                        +
                    </button>
                </div>
            </div>
        </section>
          
        </>
    );
};

export default IncreamentDecreament;
